import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import Mui from './material';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '90%',
    maxWidth: 760,
    position: 'absolute',
    zIndex: 9000,
    left: '50%',
    transform: 'translate(-50%, 20px)',
  },
}));

const CookieBanner = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { sitemap } = useSelector((state) => state.cms);
  const [show, setShow] = useState(localStorage.getItem('cookie-accepted') === 'true' ? false : true);

  const getCookieUrl = () => {
    if (sitemap) {
      const content = sitemap.find((item) => item.name === 'Våra kakor');
      if (content) return content.url;
      return '';
    }
  };

  const declineCookie = () => {
    localStorage.setItem('cookie-accepted', false);
    setShow(false);
  };

  const acceptCookie = () => {
    localStorage.setItem('cookie-accepted', true);
    setShow(false);
  };

  return (
    show && (
      <Mui.Paper elevation={5} className={classes.wrapper}>
        <Mui.Container maxWidth='md'>
          <Mui.Box p={3}>
            <Mui.Typography
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  { id: 'general.cookies.text' },
                  {
                    0: `<a href='${getCookieUrl()}' target='_blank' rel='noopener noreferrer'>`,
                    1: `</a>`,
                  }
                ),
              }}
            />

            <Mui.Box display='flex' justifyContent='flex-end' pt={2}>
              <Mui.Button variant='text' color='primary' onClick={declineCookie} disableFocusRipple>
                <FormattedMessage id='general.cookies.decline' />
              </Mui.Button>
              <Mui.Box mx={2}>
                <Mui.Button variant='text' color='primary' onClick={acceptCookie} disableFocusRipple>
                  <FormattedMessage id='general.cookies.accept' />
                </Mui.Button>
              </Mui.Box>
            </Mui.Box>
          </Mui.Box>
        </Mui.Container>
      </Mui.Paper>
    )
  );
};

export default CookieBanner;
