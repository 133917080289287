import { Route } from 'react-router-dom';

const ExternalRoute = ({ path, ...rest  }) => {
    // Remove leading slash and parameter for Contact Us-page
    let route = path.slice(1).split(':')[0];
    if (route === 'om-webbplatsen') {
        route = 'om-tjansten';
    } else if (route === 'mina-resor') {
        route = 'resor';
    } 

    return (
        <Route
            {...rest}
            render={() => {
                window.location.replace(`${window.location.origin}/ny/${route}`)
            }}
        />
    );
};

export default ExternalRoute;
