import CONSTANTS from '../constants';

import { userGet, userLogout } from '../../repository/user';
import { agentTravellerLogin, agentTravellerLogout } from '../../repository/agent';
import { queryCache } from 'react-query';

export const userGetAction = () => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'userGetAction' });

    const data = await userGet();
    dispatch({ type: CONSTANTS.SET_USER, payload: data });
    dispatch({ type: CONSTANTS.SET_PRIMARY_USER, payload: data });
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userGetAction' });
  } catch (error) {
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userGetAction' });
    dispatch(userClearAuth());
  }
};

export const userUpdateAction = () => async (dispatch) => {
  try {
    const data = await userGet();
    dispatch({ type: CONSTANTS.SET_USER, payload: data });
  } catch (error) {
    dispatch(userClearAuth());
  }
};

export const agentTravellerLoginAction = (username, history) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'agentTravellerLoginAction' });
    const auth = await agentTravellerLogin({ travellerUsername: username });
    dispatch({ type: CONSTANTS.SET_TOKEN, payload: auth });

    const data = await userGet();
    dispatch({ type: CONSTANTS.SET_USER, payload: data });
    userInvalidateQueries();
    history.push('/');
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'agentTravellerLoginAction' });
  } catch (error) {
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'agentTravellerLoginAction' });
    console.log(error);
    dispatch(userClearAuth());
  }
};

export const agentTravellerLogoutAction = (username, history) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'agentTravellerLogoutAction' });
    const auth = await agentTravellerLogout({ travellerUsername: username });
    dispatch({ type: CONSTANTS.SET_TOKEN, payload: auth });

    const data = await userGet();
    dispatch({ type: CONSTANTS.SET_USER, payload: data });
    userInvalidateQueries();
    history.push('/');
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'agentTravellerLogoutAction' });
  } catch (error) {
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'agentTravellerLogoutAction' });
    dispatch(userClearAuth());
  }
};

export const startAppWithTravellerAction = () => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'startAppWithTravellerAction' });

    const primaryToken = localStorage.getItem('primary-token');
    const userToken = localStorage.getItem('user-token');
    
    dispatch({ type: CONSTANTS.SET_TOKEN, payload: { token: primaryToken } });
    const primaryData = await userGet();
    dispatch({ type: CONSTANTS.SET_USER, payload: primaryData });
    dispatch({ type: CONSTANTS.SET_PRIMARY_USER, payload: primaryData });

    dispatch({ type: CONSTANTS.SET_TOKEN, payload: { token: userToken } });
    const travellerData = await userGet();
    dispatch({ type: CONSTANTS.SET_USER, payload: travellerData });

    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'startAppWithTravellerAction' });
  } catch (error) {
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'startAppWithTravellerAction' });
    dispatch(userClearAuth());
  }
};

export const userInvalidateQueries = () => {
  queryCache.removeQueries('userPermitsList');
  queryCache.removeQueries('orderList');
  queryCache.removeQueries('userTravellerRequestList');
  queryCache.removeQueries('userFavoriteTripsList');
  queryCache.removeQueries('userInformationList');
  queryCache.removeQueries('agentUserList');
  queryCache.removeQueries('agentTravellerList');
  queryCache.invalidateQueries('agentTravellerListPrimary');
};

export const userClearAuth = () => async (dispatch) => {
  dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'userClearAuth' });
  if (localStorage.getItem('primary-token')) {
    try {
      await userLogout();
    } catch (error) {
      console.log(error);
    }
  }
  dispatch({ type: CONSTANTS.CLEAR_AUTH });
  dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userClearAuth' });
  queryCache.clear();
};
