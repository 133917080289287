import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import { userClearAuth, agentTravellerLoginAction, agentTravellerLogoutAction } from '../store/auth/authActions';
import { agentTravellerListPrimary } from '../repository/agent';
import { getDisplayName } from '../utils/helpers';

import Mui from './material';
import UserInitials from './UserInitials';

const useStyles = makeStyles((theme) => ({
  formControl: {
    [theme.breakpoints.down('sm')]: {
      flex: '1',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 275,
    },
  },
}));

const SelectUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { loaders } = useSelector((state) => state.system);
  const { user, primaryUser } = useSelector((state) => state.auth);
  const { data: travellers } = useQuery('agentTravellerListPrimary', agentTravellerListPrimary, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    if (primaryUser && travellers && loaders.length === 0) {
      const updatedMenuItems = [
        {
          value: primaryUser.username,
          text: `${getDisplayName(primaryUser)} (inloggad)`,
        }
      ];
      travellers.forEach((traveller) => {
        updatedMenuItems.push(
          {
            value: traveller.username,
            text: getDisplayName(traveller),
          }
        );
      });
      updatedMenuItems.push(
        {
          value: 'logout',
          text: 'Logga ut',
        }
      );
      setMenuItems(updatedMenuItems);
    }
  }, [loaders, primaryUser, travellers]);
  
  const [activeUser, setActiveUser] = useState();
  useEffect(() => {
    if (user && loaders.length === 0) {
      setActiveUser(user.username);
    }
  }, [loaders, user]);

  const handleChange = async ({ target: { value } }) => {
    if (value === 'logout') {
      dispatch(userClearAuth());
    } else {
      if (value === primaryUser.username) {
        dispatch(agentTravellerLogoutAction(activeUser, history));
      } else {
        dispatch(agentTravellerLoginAction(value, history));
      }
    }
  };

  if (!activeUser) {
    return <></>;
  }

  return (
    <>
      <Mui.Box mr={2}>
        <UserInitials user={user} size='small' />
      </Mui.Box>
      <Mui.TextField
        id='select-user'
        value={activeUser}
        onChange={handleChange}
        className={classes.formControl}
        select
      >
        {menuItems.map((menuItem) => (
          <Mui.MenuItem key={menuItem.value} value={menuItem.value}>
            {menuItem.text}
          </Mui.MenuItem>
        ))}
      </Mui.TextField>
    </>
  );
};

export default SelectUser;
