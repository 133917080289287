import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ScrollToTop from 'react-router-scroll-top';
import moment from 'moment';

import Mui from './components/material';
import Icon from './components/icons';

import ProtectedRoute from './pages/ProtectedRoute';
import Start from './pages/Start';
import MyPage from './pages/MyPage';
import BookTrip from './pages/BookTrip';

import DialogContainer from './containers/shared/DialogContainer';
import SnackbarContainer from './containers/shared/SnackbarContainer';

import CookieBanner from './components/CookieBanner';
import Header from './components/Header';
import TripDetails from './pages/TripDetails';
import FooterContainer from './containers/shared/FooterContainer';
import SiteMap from './pages/default/SiteMap';
import ExternalRoute from './pages/ExternalRoute';

import LoadingOverlay from './components/LoadingOverlay';
import IdleTimer from './components/IdleTimer';

import 'moment/locale/sv';
import { systemSettingsAction, systemVersionAction } from './store/system/systemActions';
import { cmsListAction } from './store/cms/cmsActions';
import { startAppWithTravellerAction, userGetAction } from './store/auth/authActions';


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  const env = JSON.parse(localStorage.getItem('env'));

  const links = document.querySelectorAll("link");
  if (links && links.length > 0) {
    links.forEach(link => {
      link.href = link.href.split("$REGION").join(env.REGION);
    });
  }
  const title = document.querySelector("title");
  if (title) {
    title.text = title.text.split("$WEB_NAME").join(env.WEB_NAME);
  }

  const description = document.querySelector('meta[name="description"]');
  if (description) {
    const content = description.getAttribute('content');
    if (content) {
      description.setAttribute('content', content.split("$DESCRIPTION").join(env.DESCRIPTION));
    }
  }
}

moment.locale('sv');

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    '&:focus': {
      outline: 'none',
    },
  },
  skip: {
    position: 'absolute',
    left: 1,
    top: 1,
    zIndex: -99999,
    backgroundColor: '#3f5564',
    color: '#ffffff',
    width: 272,

    '&:focus, &:active': {
      zIndex: 99999,
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2, 3),
    },
    '&:hover': {
      color: '#000000',
    },
  },
}));

const App = () => {
  const classes = useStyles();
  const maincontent = useRef();
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);
  const { loaders, settings } = useSelector((state) => state.system);

  const handleFocusMain = () => {
    maincontent.current.focus();
  };

  useEffect(() => {
    dispatch(systemVersionAction());
    dispatch(systemSettingsAction());
    dispatch(cmsListAction());

    const primaryToken = localStorage.getItem('primary-token');
    const userToken = localStorage.getItem('user-token');
    if (primaryToken && userToken) {
      if (userToken === primaryToken) {
        dispatch(userGetAction());
      }
      else {
        dispatch(startAppWithTravellerAction());
      }
    }
  }, [dispatch]);

  return (
    <Router basename="/ga">
      <Mui.Button className={classes.skip} color='default' variant='contained' onClick={handleFocusMain} disableFocusRipple>
        <FormattedMessage id='general.skipToContent' />
        <Icon.ArrowForwardRoundedIcon />
      </Mui.Button>
      <CookieBanner />

      <Header />
      <main
        ref={maincontent}
        tabIndex='0'
        className={classes.main}
        aria-busy={loaders.length > 0}
        aria-live={loaders.length > 0 ? 'polite' : 'off'}>
        <Switch>
          <ProtectedRoute exact path='/boka-resa' component={Start} />
          <ProtectedRoute exact path='/boka-resa/:type/:permitId' component={BookTrip} />
          <ProtectedRoute exact path='/boka-resa/:mode/:nodeId/:type/:permitId' component={BookTrip} />
          <ProtectedRoute exact path='/mina-resor' />
          <ProtectedRoute exact path='/mina-resor/:orderId' component={TripDetails} />
          <ProtectedRoute path='/mina-sidor' component={MyPage} />
          <ExternalRoute exact path='/kontakta-oss/:section' />
          <Route exact path='/webbplatskarta' component={SiteMap} />
          <ExternalRoute exact path='/om-webbplatsen' />
          <ExternalRoute exact path='/tillganglighet' />
          <ExternalRoute exact path='/cookies' />
          <ExternalRoute exact path='/version' />

          {!isAuthenticated && <ExternalRoute exact path='/login' />}
          {!isAuthenticated && <Redirect to='/login' />}
          {isAuthenticated && <Redirect exact from='/' to='/mina-resor' />}
          {isAuthenticated && <Redirect to='/mina-resor' />}
        </Switch>
      </main>
      <FooterContainer />

      <DialogContainer />
      <SnackbarContainer />
      <ScrollToTop />
      {loaders.length > 0 && <LoadingOverlay />}
      {isAuthenticated && settings.app_inactive_logout && <IdleTimer />}
    </Router>
  );
};

export default App;
