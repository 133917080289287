import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ExternalRoute from './ExternalRoute';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, primaryUser } = useSelector((state) => state.auth);
  
  // Capture migrated pages
  const { path } = rest;
  if (path === '/mina-resor') {
    return <ExternalRoute path={path} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (primaryUser) {
            return <Component {...rest} {...props} />;
          }
        } else {
          return <Redirect to='/login' />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
