import CONSTANTS from '../constants';
import { decodeToken } from 'react-jwt';

const initialState = {
  isAuthenticated: localStorage.getItem('primary-token') ? true : false,
  token: localStorage.getItem('user-token') ? localStorage.getItem('user-token') : null,
  primaryToken: localStorage.getItem('primary-token') ? localStorage.getItem('primary-token') : null,
  customer: null,
  user: null,
  primaryUser: null,
  travellers: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_TOKEN:
      const token = decodeToken(action.payload.token);

      if ('traveller' in token) {
        const traveller = decodeToken(token.traveller);
        localStorage.setItem('has-key', traveller.hasKey);
      } else {
        localStorage.setItem('has-key', token.hasKey);
      }
      localStorage.setItem('user-token', action.payload.token);

      return {
        ...state,
        token: action.payload.token,
      };
    
    case CONSTANTS.SET_PRIMARY_TOKEN:
      localStorage.setItem('primary-token', action.payload.token);

      return {
        ...state,
        isAuthenticated: action.payload.token ? true : false,
        primaryToken: action.payload.token,
      };

    case CONSTANTS.SET_PRIMARY_USER:
      return {
        ...state,
        primaryUser: action.payload.data.rattenUser,
      };

    case CONSTANTS.SET_USER:
      return {
        ...state,
        isAuthenticated: action.payload.success,
        user: action.payload.data.rattenUser,
        customer: action.payload.data.planitCustomer,
        homeAddress: action.payload.data.homeAddress,
      };

    case CONSTANTS.SET_TRAVELLERS:
      return {
        ...state,
        travellers: action.payload,
      };

    case CONSTANTS.CLEAR_AUTH:
      localStorage.removeItem('primary-token');
      localStorage.removeItem('user-token');
      localStorage.removeItem('has-key');
      localStorage.removeItem('refresh-token');

      return {
        isAuthenticated: false,
        token: null,
        customer: null,
        user: null,
      };

    default:
      return state;
  }
};

export default authReducer;
